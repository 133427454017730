import { render, staticRenderFns } from "./QuayDetail.vue?vue&type=template&id=246aa233&scoped=true&"
import script from "./QuayDetail.vue?vue&type=script&lang=js&"
export * from "./QuayDetail.vue?vue&type=script&lang=js&"
import style0 from "./QuayDetail.vue?vue&type=style&index=0&id=246aa233&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246aa233",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCol,VDivider,VIcon,VOverlay,VRow})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{staticClass:"text-center",attrs:{"value":_vm.loading,"color":"white","opacity":"1"}},[_c('img',{staticClass:"rotate",attrs:{"src":require('@/assets/icons/spinner.svg')}}),_c('div',{staticClass:"black--text"},[_c('h1',[_vm._v("Persoonlijk advies.")]),_c('p',[_vm._v(" We berekenen welke OV-haltes"),_c('br'),_vm._v(" voor jou toegankelijk zijn. ")])])]),_c('div',{staticClass:"pa-2 profile-wrapper"},[_c('v-form',[_c('v-card',[_c('v-card-text',[_c('h2',[_vm._v("Hoe verplaats je je?")]),_c('v-radio-group',{on:{"change":_vm.setModalityDefaults},model:{value:(_vm.profileLocal.modality),callback:function ($$v) {_vm.$set(_vm.profileLocal, "modality", $$v)},expression:"profileLocal.modality"}},_vm._l((_vm.modalities),function(n){return _c('v-radio',{key:n,attrs:{"value":n},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(n)+" "),_c('img',{staticClass:"pl-1",attrs:{"src":require(("@/assets/icons/modalities/" + n + ".svg"))}})]},proxy:true}],null,true)})}),1),_c('p',[_vm._v(" We vullen alvast waarden in die passen bij de meeste mensen "+_vm._s(_vm.profileLocal.modality === "Zonder hulpmiddel" ? "zonder hulpmiddel" : ("met een " + (_vm.profileLocal.modality.toLowerCase())))+". ")])],1)],1),_c('div',{staticClass:"pb-6"}),_c('v-card',[_c('v-card-text',[_c('h2',[_vm._v("Hoeveel breedte heb je nodig?")]),_c('v-slider',{staticClass:"mt-14",attrs:{"prepend-icon":" mdi-chevron-double-left","append-icon":" mdi-chevron-double-right","thumb-label":"always","min":"40","max":"150","thumb-size":"40"},on:{"click:prepend":function (v) { return _vm.changeProfile({
                  prop: 'width',
                  value: _vm.profileLocal.width - 1,
                }); },"click:append":function (v) { return _vm.changeProfile({
                  prop: 'width',
                  value: _vm.profileLocal.width + 1,
                }); },"end":function (v) { return _vm.changeProfile({ prop: 'width', value: v }); }},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
                var value = ref.value;
return [_vm._v(" "+_vm._s(value)+"cm ")]}}]),model:{value:(_vm.profileLocal.width),callback:function ($$v) {_vm.$set(_vm.profileLocal, "width", $$v)},expression:"profileLocal.width"}})],1)],1),_c('div',{staticClass:"pb-6"}),_c('v-card',[_c('v-card-text',[_c('h2',[_vm._v(" Hoe hoog mag een drempel of de hoogte tot een voertuig zijn? ")]),_c('v-radio-group',{attrs:{"mandatory":""},on:{"change":function (v) { return _vm.changeProfile({ prop: 'threshold', value: v }); }},model:{value:(_vm.profileLocal.threshold),callback:function ($$v) {_vm.$set(_vm.profileLocal, "threshold", $$v)},expression:"profileLocal.threshold"}},[_c('v-radio',{attrs:{"label":"Maximaal 2 cm","value":2}}),_c('v-radio',{attrs:{"label":"Maximaal 5 cm","value":5}}),_c('v-radio',{attrs:{"label":"Maximaal 10 cm","value":10}}),_c('v-radio',{attrs:{"label":"Maximaal 15 cm","value":15}}),_c('v-radio',{attrs:{"label":"Hoogte maakt niet uit","value":Infinity}})],1)],1)],1),_c('div',{staticClass:"pb-6"}),_c('v-card',[_c('img',{staticClass:"float-right pt-4 pr-6 pl-3",attrs:{"src":require('@/assets/icons/quayRamp.svg')}}),_c('v-card-text',[_c('h2',[_vm._v(" Wil je gebruik maken van een oprijplank bij hogere drempels? ")]),_c('v-switch',{attrs:{"label":"Ja"},on:{"change":function (v) { return _vm.changeProfile({ prop: 'ramp', value: v }); }},model:{value:(_vm.profileLocal.ramp),callback:function ($$v) {_vm.$set(_vm.profileLocal, "ramp", $$v)},expression:"profileLocal.ramp"}})],1)],1)],1),[_c('div',{staticClass:"pb-6"}),_c('v-btn',{staticClass:"text-none text-body rounded-0",attrs:{"color":"secondary","block":"","depressed":""},on:{"click":function($event){return _vm.saveProfile()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_c('strong',[_vm._v("Sla je reisprofiel op")])],1),_c('div',{staticClass:"pb-12"})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }